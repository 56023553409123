// The list is in inverted chronological order.
// First items are the latest.

module.exports = [
  {
    title: 'Universal',
    message: `Universal usage in any situation, flexibility and options to expand features (one of our main strengths).`,
    link: '/features',
    image: '/images/universal.png'
  },
  {
    title: 'Artificial',
    message: `Artificial General Intelligence is a hypothetical intelligence of intellectual machine.`,
    link: '/features',
    image: '/images/artificial.png'
  },
  {
    title: 'General',
    message: `AI completes problems, which are hypothesised to include general computer vision.`,
    link: '/features',
    image: '/images/general.png'
  },
  {
    title: 'Intelligence',
    message: `AI understands unexpected circumstances and solve any real world problem in real-time.`,
    link: '/features',
    image: '/images/intelligence.png'
  }
];
